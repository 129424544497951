<template>
  <div class="lecture-play">
    <v-header/>
    <d-player v-show="currLecture.hasBuy || currLecture.isTrial" ref="player" @loaded="loadFirstLecture"/>
    <div class="course-body" v-if="!currLecture.hasBuy && !currLecture.isTrial">
      <div class="not-buy">
        <div>立刻购买开启完整课程~</div>
        <router-link :to="`/shop/goods/${goods.id}`" class="btn btn-primary buy-btn">
          前往购买
        </router-link>
      </div>
    </div>

    <van-tabs sticky scrollspy offset-top="10.667vw">
      <van-tab title="课程目录">
        <ul class="course-direction-list">
          <li :class="`course-direction-item ${currLecture.id===item.id?'active':''}`" v-for="item in lectures"
              :key="item.id" @click="switchLecture(item.id)">
            <div style="display: flex;align-items: center">{{ item.title }}
              <trial-icon v-if="item.isTrial"/>
            </div>
            <div class="course-direction-item-time"><i class="el-icon-time"></i> {{ secondsToDayInfo(item.seconds) }}
            </div>
          </li>
        </ul>

      </van-tab>
      <van-tab title="逐字稿">
        <div class="ql-snow">
          <div class="ql-editor" v-html="currLecture.detail"></div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>


------------------ script ------------------
<script>
import DPlayer from "../../components/dPlayer";
import {goodsV2Api} from "../../api/v2/goodsV2Api";
import {goodsLectureV2Api} from "../../api/v2/goodsLectureV2Api";
import TrialIcon from "../../components/shop/trialIcon";

export default {
  components: {DPlayer, TrialIcon},

  data() {
    return {
      goods: {
        id: '',
        title: ''
      },
      lectures: [],
      currLecture: {
        id: '',
        isTrial: false,
        hasBuy: false,
        url: '',
        detail: ''
      },
    }
  },

  created() {
    this.loadGoods();
  },


  methods: {
    // 加载商品信息
    async loadGoods() {
      const id = this.$route.params.id;
      this.goods = await goodsV2Api.findById(id);
    },


    // 加载进入页面的课时
    async loadFirstLecture() {
      await this.loadLectures();
      if (this.$route.query.lectureId) {
        this.switchLecture(this.$route.query.lectureId).then();
      } else {
        this.switchLecture(this.lectures[0].id).then();
      }
    },


    // 加载课程课时
    async loadLectures() {
      this.lectures = await goodsLectureV2Api.findByGoodsId(this.$route.params.id)
    },


    nextLecture() {
      const idx = this.lectures.findIndex(l => l.id === this.currLecture.id);
      const nextIdx = idx + 1;
      if (nextIdx >= this.lectures.length) {
        return;
      }
      this.switchLecture(this.lectures[nextIdx].id)
    },


    async switchLecture(id) {
      id = parseInt(id)
      this.$refs.player.pause()
      const res = await goodsLectureV2Api.findLectureUrlById(id);
      const lecture = this.lectures.find(l => l.id === id) || {};
      this.currLecture = {
        ...lecture, ...res, id
      }
      window.scrollTo({top: 0, behavior: 'smooth'})
      if (this.currLecture.hasBuy || this.currLecture.isTrial) {
        this.$refs.player.switchVideo({
          url: res.url,
        })
        this.$refs.player.play();
      }
    }
  }
}
</script>


------------------ styles ------------------
<style lang="less" scoped>
.lecture-play {
  padding-top: 40px;
}

.course-direction-list {
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e9e9e9;
}

.course-direction-item {
  padding: 0 32px;
  color: #151D36;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 16px;

  &:hover, &.active {
    color: #ee6c71;
  }
}

.course-direction-item-time {
  color: #999;
  font-size: 12px;
  padding-top: 8px;
  display: flex;
  align-items: center;

  .el-icon-time {
    margin-right: 4px;
  }
}

.course-body {
  display: flex;
  height: calc(100vw * 9 / 16);
  background: #151D36;
}

.not-buy {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  color: #fff;
  font-size: 14px;
}

.buy-btn {
  height: 40px;
  width: 160px;
  background: #ee4a50;
  margin-top: 16px;
  padding: 0;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

</style>
