<template>
  <div ref="playerContainer"></div>
</template>


------------------ script ------------------
<script>
import DPlayer from 'dplayer'

export default {

  data() {
    return {
      player: null
    }
  },


  mounted() {
    this.createPlayer();
  },


  methods: {

    // 创建播放器
    createPlayer() {
      this.player = new DPlayer({
        container: this.$refs.playerContainer,
        theme: '#151d36',
        autoplay: true,
        video: {
          url: '',
          pic: ''
        },
      });
      this.player.on('ended', () => {
        this.$emit('ended')
      })
      this.$emit('loaded')
    },


    play() {
      this.player.play();
    },

    pause() {
      this.player.pause();
    },

    seek(time) {
      this.player.seek(time)
    },

    // 切换视频
    switchVideo(options) {
      this.player.switchVideo(options);
    },

  }

}
</script>


------------------ styles ------------------
<style lang="less" scoped>

</style>
